import React, { useState } from "react"
import styled from "@emotion/styled"
import { Input, Label, Flex } from "theme-ui"
import PeekEyeOpen from "../../../assets/svg/peek-eye-open.svg"
import PeekEyeClosed from "../../../assets/svg/peek-eye.svg"

const InputFieldContainer = styled(Flex)`
  flex-direction: column;

  input {
    border: ${(props) =>
      props.inputSx?.border ?? props.theme.borders.brownGrey};
    background-color: ${(props) =>
      props.inputSx?.backgroundColor ?? props.theme.colors.white};
    color: ${(props) => props.inputSx?.color ?? props.theme.colors.black};
    font-weight: normal;
    box-sizing: border-box;
    padding-left: ${(props) => props.inputSx?.paddingLeft ?? "16px"};
    border-radius: 0px;
    height: ${(props) => (props.sx?.height ? props.sx.height : "40px")};

    &:focus {
      outline: none;
      border: ${(props) => props.inputSx?.border ?? props.theme.borders.dark};
    }

    ${(props) =>
      props.hasError &&
      ` 
        color: ${props.theme.colors.red} !important;
        border: ${props.theme.borders.danger}!important;   
      `}
  }

  .peeking-eye {
    display: none;
    position: absolute;
    top: 42px;
    right: 10px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  ${(props) =>
    props.allowPeek &&
    `
    position: relative;

      .peeking-eye {
        display: block;
      }
   `}

  .error {
    color: ${(props) => props.theme.colors.red};
  }
`

const InputField = ({
  autoFocus,
  disabled,
  placeholder,
  label,
  type,
  ref,
  value,
  onChange,
  onBlur,
  onKeyPress,
  className,
  allowPeek,
  name,
  error,
  sx,
  inputSx,
  labelSx,
  errorSx,
  ...rest
}) => {
  const [isPeeking, setIsPeeking] = useState(false)

  const handlePeek = () => {
    if (allowPeek && isPeeking) {
      return "text"
    }

    return type
  }

  return (
    <InputFieldContainer
      hasError={!!error}
      className={className}
      sx={sx}
      allowPeek={allowPeek}
      inputSx={inputSx}
      {...rest}
    >
      {label && <Label sx={{ mb: "8px", ...labelSx }}>{label}</Label>}
      <Input
        ref={ref}
        autoFocus={autoFocus}
        disabled={disabled}
        type={handlePeek()}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        value={value || ""}
        sx={inputSx}
      />
      {allowPeek === true &&
        (isPeeking ? (
          <PeekEyeClosed
            className="peeking-eye"
            onClick={() => setIsPeeking((isPeeking) => !isPeeking)}
          />
        ) : (
          <PeekEyeOpen
            className="peeking-eye"
            onClick={() => setIsPeeking((isPeeking) => !isPeeking)}
          />
        ))}

      {error && (
        <Label sx={errorSx} className="error">
          {error}
        </Label>
      )}
    </InputFieldContainer>
  )
}

export default InputField
