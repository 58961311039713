const noDivisionCurrencies = ["krw"]

export function normalizeAmount(currency, amount) {
  let divisor = 100
  if (noDivisionCurrencies.includes(currency.toLowerCase())) {
    divisor = 1
  }

  return Math.floor(amount) / divisor
}

export function formatCartItemPrice(cart, cartItem) {
  const variantPrice = getVariantPrice(cart, cartItem.variant)
  const currencyCode = cart.currencyCode || cart.currency_code

  return formatMoneyAmount({
    currencyCode,
    amount: variantPrice * cartItem.quantity,
  })
}

function getTaxRate(cart) {
  if ("taxRate" in cart) {
    return cart.taxRate / 100
  } else if ("tax_rate" in cart) {
    return cart.tax_rate / 100
  } else if (cart.region) {
    return cart.region && cart.region.tax_rate / 100
  }
  return 0.25
}

export function getVariantPrice(cart, variant) {
  const currencyCode = cart.currencyCode || cart.currency_code
  let taxRate = getTaxRate(cart)
  let moneyAmount = variant.prices.find(
    (p) => p.currency_code.toLowerCase() === currencyCode.toLowerCase()
  )

  if (moneyAmount && moneyAmount.amount) {
    return moneyAmount.amount * (1 + taxRate)
  }

  return undefined
}

export function formatMoneyAmount(moneyAmount, digits, taxRate = 0) {
  let locale = "en-US"

  // We need this to display 'Kr' instead of 'DKK'
  if (moneyAmount.currencyCode.toLowerCase() === "dkk") {
    locale = "da-DK"
  }

  if (moneyAmount.currencyCode.toLowerCase() === "krw") {
    digits = 0
  }

  const normalizedAmount = normalizeAmount(
    moneyAmount.currencyCode,
    moneyAmount.amount
  )

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: moneyAmount.currencyCode,
    minimumFractionDigits: digits,
  }).format(normalizedAmount * (1 + taxRate / 100))
}

export function formatPrices(cart, prices, digits = 2) {
  let amount = prices
  const currencyCode = cart.currencyCode || cart.currency_code

  if (typeof prices === "object") {
    amount = prices.find(
      (p) => p.currencyCode.toLowerCase() === currencyCode.toLowerCase()
    )
  }

  if (noDivisionCurrencies.includes(currencyCode.toLowerCase())) {
    digits = 0
  }

  const taxRate = getTaxRate(cart)
  return formatMoneyAmount(
    {
      currencyCode,
      amount: amount * (1 + taxRate),
    },
    digits
  )
}

export function formatVariantPrice(cart, variant, digits = 2) {
  if (!cart || !variant) return
  const currencyCode = cart.currency || cart.currencyCode
  return formatMoneyAmount(
    {
      currencyCode,
      amount: getVariantPrice(cart, variant),
    },
    digits
  )
}

export function formatCartShippingTotal(cart, includeTax = true) {
  let shippingTotal =
    "shipping_total" in cart ? cart.shipping_total : cart.shippingTotal
  if (includeTax) {
    const taxRate = getTaxRate(cart)
    shippingTotal += shippingTotal * taxRate
  }

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: shippingTotal,
  })
}

export function formatCartSubtotal(cart, includeTax = true) {
  let subtotal = cart.subtotal
  if (includeTax) {
    const taxRate = getTaxRate(cart)
    subtotal += subtotal * taxRate
  }

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: subtotal,
  })
}

export function formatVatDeduction(cart) {
  let subtotal = cart.subtotal || cart.subTotal

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: subtotal * 0.25,
  })
}

export function formatCartTotal(cart, allowNegative = true) {
  const currencyCode = cart.currencyCode || cart.currency_code
  let amount = cart.total
  if (!allowNegative && cart.total < 0) {
    amount *= -1
  }
  return formatMoneyAmount({
    currencyCode,
    amount,
  })
}

export function formatCartGiftCard(cart) {
  const giftCardTotal =
    "gift_card_total" in cart ? cart.gift_card_total : cart.giftCardTotal
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)
  return formatMoneyAmount({
    currencyCode,
    amount: giftCardTotal * (1 + taxRate),
  })
}

export function formatCartDiscount(cart) {
  const discountTotal =
    "discount_total" in cart ? cart.discount_total : cart.discountTotal
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)
  return formatMoneyAmount({
    currencyCode,
    amount: discountTotal * (1 + taxRate),
  })
}

export function formatShipping(amount, cart) {
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)

  return formatMoneyAmount({
    currencyCode,
    amount: amount * (1 + taxRate),
  })
}

export function formatOrderDiscount(order) {
  const currencyCode = order.currencyCode || order.currency_code
  const taxRate = getOrderTaxRate(order)
  return formatMoneyAmount({
    currencyCode,
    amount: order.discount_total * (1 + taxRate),
  })
}

function getOrderTaxRate(order) {
  if ("tax_rate" in order) {
    return order.tax_rate / 100
  } else if (order.region) {
    return order.region && order.region.tax_rate / 100
  }
  return 0.25
}

export function formatOrderShipping(price, order) {
  const currencyCode = order.currencyCode || order.currency_code
  const taxRate = getOrderTaxRate(order)
  return formatMoneyAmount({
    currencyCode,
    amount: (price += price * taxRate),
  })
}

// Calculates the minimum price of a selcted color's sizes.
// If the selected product represents a bedding collection we show the
// minimum price of the pillow product
export const getMinimumPriceOption = (product, cart) => {
  if (!product || !cart) return
  if (!product || !product.variants?.length) return 0
  const currencyCode = cart.currencyCode
  let taxRate = parseFloat(cart.taxRate)

  const init =
    product.variants[0].prices.find(
      (ma) => ma.currency_code.toLowerCase() === currencyCode.toLowerCase()
    ).amount || 0
  const minPrice = product.variants.reduce((acc, next) => {
    const current = next.prices.find(
      (ma) => ma.currency_code.toLowerCase() === currencyCode.toLowerCase()
    ).amount

    if (current < acc) {
      return current
    } else {
      return acc
    }
  }, init)

  return (minPrice * (100 + taxRate)) / 100
}
